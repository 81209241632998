import './Footer.css';
import LOGO from '../../assets/logo_3.png';

function Footer() {
  return (
    <footer>
      <a href="#" className="footer__logo">
        <img src={LOGO} alt=""/>
      </a>

      <ul className="permalinks">
        <li><a href="#home">Home</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#skills">Skills</a></li>
        <li><a href="#certifications">Certifications</a></li>
        <li><a href="#contact">Contact</a></li>
      </ul>

      {/*
      <h1 style={{color:"#420516"}}>test red6</h1>
      */}

      <div className="footer__copyright">
        <small>&copy; Marcin Cabaj DevOps Ninja. All rights reserved.</small>
      </div>
    </footer>
  );
}

export default Footer;
