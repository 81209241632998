import './Skills.css';

function Skills() {
  return (
    <section id="skills" className="skills">
      <h5>What Experience I Have</h5>
      <h2>Skills</h2>
      <div className="container skills__container">

        <article className="skill">
          <strong>Linux</strong>: detailed knowledge including kernel internals
        </article>

        <article className="skill">
          <strong>Networking</strong>: solid understanding of routing, switching, load balancing
        </article>

        <article className="skill">
          <strong>Programming languages</strong>: GO, Python, Bash
        </article>

        <article className="skill">
          <strong>Cloud and virtualization</strong>: AWS, Libvirt, Cloud-init
        </article>

        <article className="skill">
          <strong>Container technologies</strong>: Kubernetes, Docker
        </article>

        <article className="skill">
          <strong>IAC, GitOps and Config Management</strong>: Terraform, Helm, Ansible, Chef
        </article>

        <article className="skill">
          <strong>Observability</strong>: Prometheus, Loki, Grafana
        </article>

        <article className="skill">
          <strong>Continuous integration</strong>: ArgoCD, CircleCi, GitHub Actions, BitBucket Pipelines
        </article>

        {/*
        Frontend Development: jk, although, this portfolio website was created by myslef from scratch:)

        I'm focused:
        - IAC
        - Automation
        - Easy deployments
        - Simplicity
        - Observability...metrics metrics metrics (big fan of Prom stack)
        */}

      </div>
    </section>
  );
}

export default Skills;
