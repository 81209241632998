import './About.css';
import ME from '../../assets/aboutme.png'
import { BsKeyboard } from "react-icons/bs";
import { FaAward } from "react-icons/fa";
import { BsGithub } from "react-icons/bs";

function About() {
  return (
    <section id="about">
      <h5>Get To Know</h5>
      <h2>About Me</h2>
      <div className="container about__container">
        <div className="about__me">
          <img className="about__me-image" src={ME} alt=""/>
        </div>
        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <BsKeyboard class="about__icon"/>
              <h5>Experience</h5>
              <small>10+ Years Working</small>
            </article>
            <article className="about__card">
              <FaAward class="about__icon"/>
              <h5>Certifications</h5>
              <small>5x AWS<br/>2x K8S</small>
            </article>
            {/*
            <article className="about__card">
              <BsGithub class="about__icon"/>
              <h5>Commits/Github</h5>
              <small>...</small>
            </article>
            */}
          </div>

          <p>
            I’m Marcin. I’m a DevOps and SRE enthusiast with over 10 years of experience within various environments.
            I believe in automation, simplicity and good architecture.<br/><br/>
            My specialisations are <strong>Amazon Web Services</strong> and <strong>Kubernetes</strong>.<br/><br/>
            I am looking for remote opportunities/projects. Don’t be a stranger and get in touch.
          </p>
          <a href="#contact">
            <button>
              Contact Me
            </button>
          </a>

        </div>
      </div>
    </section>
  );
}

export default About;
