import './Home.css';
import CV from '../../assets/Marcin_Cabaj_CV.pdf'

function Home() {
  return (
    <section id="home" className="home">
      <div className="container home__container">
        <div className="home__h1">Hi, I'm Marcin Cabaj</div>
        <div className="home__h2 text-primary">DevOps Ninja</div>
        <div className="home__h3">Skilled professional specialising<br/>
          in <span className="home__h2 text-primary">AWS</span> and <span className="home__h2 text-primary">K8S</span></div>
        {/*
        <div className="home__h3">Expert in AWS and K8S</div>
        */}

        <div className="home__cta">
          <a href="#contact">
            <button>
              Contact Me
            </button>
          </a>
          <a href={CV} download='Marcin_Cabaj_CV.pdf'>
            <button>
              Download CV
            </button>
          </a>
        </div>
      </div>
    </section>
  );
}

export default Home;
