import './Nav.css';
import { AiOutlineHome } from "react-icons/ai";
import { AiOutlineUser } from "react-icons/ai";
import { BiWrench } from "react-icons/bi";
import { FaAward } from "react-icons/fa";
import { BiMessageSquareDetail } from "react-icons/bi";

function Nav() {
  return (
    <nav>
      <a href="#" title="Home"><AiOutlineHome/></a>
      <a href="#about" title="About"><AiOutlineUser/></a>
      <a href="#skills" title="Skills"><BiWrench/></a>
      <a href="#certifications" title="Certifications"><FaAward/></a>
      <a href="#contact" title="Contact"><BiMessageSquareDetail/></a>
    </nav>
  );
}

export default Nav;
