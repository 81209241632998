import './App.css';
import Nav from './components/nav/Nav';
import Home from './components/home/Home';
import About from './components/about/About';
import Skills from './components/skills/Skills';
import Certifications from './components/certifications/Certifications';
import Contact from './components/contact/Contact';
import Footer from './components/footer/Footer';

function App() {
  return (
    <>
      <Nav/>
      <Home/>
      <About/>
      <Skills/>
      <Certifications />
      <Contact />
      <Footer />
    </>
  );
}

export default App;
