import './Contact.css';

import {MdOutlineEmail} from 'react-icons/md'
import {BsWhatsapp} from 'react-icons/bs'
import {BsLinkedin} from 'react-icons/bs'

function Contact() {
  return (
    <section id="contact">
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>

      <div className="container contact__container">
          <article className="contact__option">
            <MdOutlineEmail className="contact__option-icon"/>
            <h4>Email</h4>
            <h5>mc@devopsninja.info</h5>
            <a href="mailto:mc@devopsninja.info" target="_blank">Send an email</a>
          </article>

          <article className="contact__option">
            <BsWhatsapp className="contact__option-icon"/>
            <h4>WhatsApp</h4>
            <h5>+48796709696</h5>
            <a href="https://api.whatsapp.com/send?phone=+48796709696" target="_blank">Send a message</a>
          </article>

          <article className="contact__option">
            <BsLinkedin className="contact__option-icon"/>
            <h4>LinkedIn</h4>
            <a href="https://linkedin.com/in/marcin-cabaj-74183675" target="_blank">Go to profile page</a>
          </article>

        {/*
          <form>
            <input type="text" name="name" placeholder="Your Full Name" required />
            <input type="email" name="email" placeholder="Your Email" required />
            <textarea name="message" rows="7" placeholder="Your Message" required ></textarea>
            <button type="submit" className="btn">Send Message</button>
          </form>
        */}

      </div>
    </section>
  );
}

export default Contact;
