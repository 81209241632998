import './Certifications.css';
import CERT_K8S_1 from '../../assets/cers/cert_k8s_cka.png'
import CERT_K8S_2 from '../../assets/cers/cert_k8s_ckad.png'
import CERT_AWS_1 from '../../assets/cers/cert_aws_devops.png'
import CERT_AWS_2 from '../../assets/cers/cert_aws_sa_prof.png'
import CERT_AWS_3 from '../../assets/cers/cert_aws_sysops.png'
import CERT_AWS_4 from '../../assets/cers/cert_aws_sa_associate.png'
import CERT_AWS_5 from '../../assets/cers/cert_aws_cp.png'


function Certifications() {
  return (
    <section id="certifications" className="section__certifications">

      <h5>Verified Competence</h5>
      <h2>Certifications</h2>

      <div className="container certifications__container">
        <article className="certifications__item">
          <div className="certifications__item-image">
            <img src={CERT_K8S_1} alt=""/>
          </div>
          <h3>CKA: Certified Kubernetes Administrator</h3>
          <div className="mask__efect"></div>
        </article>

        <article className="certifications__item">
          <div className="certifications__item-image">
            <img src={CERT_K8S_2} alt=""/>
          </div>
          <h3>CKAD: Certified Kubernetes Application Developer</h3>
        </article>

        <article className="certifications__item">
          <div className="certifications__item-image">
            <img src={CERT_AWS_1} alt=""/>
          </div>
          <h3>AWS Certified DevOps Engineer – Professional</h3>
        </article>

        <article className="certifications__item">
          <div className="certifications__item-image">
            <img src={CERT_AWS_2} alt=""/>
          </div>
          <h3>AWS Certified Solutions Architect – Professional</h3>
        </article>

        <article className="certifications__item">
          <div className="certifications__item-image">
            <img src={CERT_AWS_3} alt=""/>
          </div>
          <h3>AWS Certified SysOps Administrator – Associate</h3>
        </article>

        <article className="certifications__item">
          <div className="certifications__item-image">
            <img src={CERT_AWS_4} alt=""/>
          </div>
          <h3>AWS Certified Solutions Architect – Associate</h3>
        </article>

        <article className="certifications__item">
          <div className="certifications__item-image">
            <img src={CERT_AWS_5} alt=""/>
          </div>
          <h3>AWS Certified Cloud Practitioner</h3>
        </article>
      </div>
    </section>
  );
}

export default Certifications;
